module services {
    export module costing {

        export class costParameterProductCategoryService implements interfaces.costing.ICostParameterProductCategoryService {

            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getProductCategoryList(): ng.resource.IResourceClass<interfaces.costing.ICostProductCategory> {
                return this.$resource<interfaces.costing.ICostProductCategory>(this.ENV.DSP_URL + "CostParameterProductCategory/GetProductCategoryList", {
                    showAllActive: '@showAllActive',
                    entityId: '@entityId',
                    productCategoryId: '@productCategoryId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            saveProductCategory(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterProductCategory/SaveProductCategory", {
                });
            }

            removeProductCategory(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterProductCategory/RemoveProductCategory", {
                    cpuId: '@cpuId',
                });
            }


        }

    }

    angular.module("app").service("costParameterProductCategoryService", services.costing.costParameterProductCategoryService);
}